import React, { Component } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "utils/Media"
import { ShopContext } from "utils/ShopContext"
import "fragments/optionsFragment"
import ContainerMax from "components/shared/ContainerMax"
import ProductSlider from "./ProductSlider"
import ProductVariantSelector from "./ProductVariantSelector"
import RelatedProducts from "./RelatedProducts"

const Title = styled.h1`
    color: ${props => props.theme.colors.blackOff};
    font-weight: ${props => props.theme.font.weight.medium};
    font-size: ${props => props.theme.font.h4.size};
    margin: 0;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: .5rem;

    @media ${media.md} {
        font-size: ${props => props.theme.font.h2.size}; 
        text-align: left;
        padding-top: 0;
    }
`

const Price = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.font.h4.size};
    font-weight: ${props => props.theme.font.weight.medium};
    text-align: center;
    padding-bottom: .5rem;

    @media ${media.md} {
        font-size: ${props => props.theme.font.h2.size}; 
        text-align: left;
    }
`

const Stock = styled.div`
    margin: 1rem 0;
    padding-bottom: .5rem;
    font-weight: ${props => props.theme.font.weight.bold};
`

const Quantity = styled.input`
    padding: .5rem;
    font-weight: ${props => props.theme.font.weight.bold};
    width: 100px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
`

const BuyButton = styled.button`
    background-color: ${props => props.theme.colors.secondary};
    border: 0;
    color: ${props => props.theme.colors.white};
    font-weight: ${props => props.theme.font.weight.bold};
    padding: .75rem 2rem;
    width: 100%;

    &:disabled {
        opacity: .6;
    }

    @media ${media.md} {
        padding: .75rem 4rem; 
        width: auto;
    }
`

const CollapseButton = styled.button`
    background: transparent;
    border: 0;
    position: relative;
    padding: 0 0 0 1rem;
    margin-bottom: .5rem;

    &:before,
    &:after {
        content: "";
        height: 10px;
        width: 2px;
        background-color: black;
        position: absolute;
        top: .4rem;
        left: 0;
    }

    &:after {
        transform: rotate(-90deg);
    }

    &.active {
        &:before {
            display: none;
        }
    }
`

class ProductContent extends Component {

    constructor(props) {
        super(props)

        let defaultOptionValues = {}
        this.props.product.options.forEach((selector) => {
            defaultOptionValues[selector.name] = selector.values[0]
        })

        this.state = { 
            selectedOptions: defaultOptionValues,
            isDetailsOpen: true,
            isReturnsOpen: false
        }

        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.handleOptionChange = this.handleOptionChange.bind(this)
        this.handleQuantityChange = this.handleQuantityChange.bind(this)
    }

    toggleCollapse(type) {
        if (type === "details") {
            this.setState({
                isDetailsOpen: !this.state.isDetailsOpen
            })
        } else {
            this.setState({
                isReturnsOpen: !this.state.isReturnsOpen
            })
        }
    }

    handleOptionChange(name, value) {
        let selectedOptions = this.state.selectedOptions
        selectedOptions[name] = value
    
        const selectedVariant = this.context.client.product.helpers.variantForOptions(this.props.product, selectedOptions)
    
        this.setState({
            selectedVariant: selectedVariant,
            // selectedVariantImage: selectedVariant.attrs.image
        })
    }

    handleQuantityChange(event) {
        this.setState({
            selectedVariantQuantity: event.target.value
        })
    }

    render() {
        const { product } = this.props
        const { deliveryAndReturnsText } = this.props.data.allWp.edges[0].node.options.acfOptions
        // let variantImage = this.state.selectedVariantImage || product.images[0]
        let variant = this.state.selectedVariant || product.variants[0]
        let variantQuantity = this.state.selectedVariantQuantity || 1
        let variantSelectors = product.options.map((option) => {
            return (
                <ProductVariantSelector
                    handleOptionChange={this.handleOptionChange}
                    key={option.shopifyId.toString()}
                    option={option}
                />
            )
        })

        return(
            <ContainerMax>
                <Row className="py-4">
                    <Col md={6} lg={8}>
                        <ProductSlider product={product} />
                    </Col>
                    <Col md={6} lg={4}>
                        <Title>{product.title}</Title>
                        <Price>&pound;{Number(variant.price).toFixed(2)}</Price>
                        <Stock>
                            {variant.availableForSale ? (
                                "IN STOCK"
                            ) : (
                                "OUT OF STOCK"
                            )}
                        </Stock>
                        {(product.options > 1 || product.options[0].name !== "Title") &&
                            variantSelectors
                        }
                        <Row>
                            <Col className="pb-4">
                                <label htmlFor="quantity">
                                    <div className="pb-2 text-uppercase">
                                        <strong>QUANTITY</strong>
                                    </div>
                                    <Quantity 
                                        min="1" 
                                        type="number" 
                                        name="quantity" 
                                        defaultValue={variantQuantity}
                                        onChange={this.handleQuantityChange} 
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pb-5">
                                <BuyButton 
                                    onClick={() => this.context.addVariantToCart(variant.shopifyId, variantQuantity)}
                                    disabled={!variant.availableForSale}
                                >
                                    Add to bag
                                </BuyButton>
                            </Col>
                        </Row>
                        {product.descriptionHtml &&
                            <Row>
                                <Col className="pb-3">
                                    <CollapseButton 
                                        className={this.state.isDetailsOpen ? "active" : ""}
                                        onClick={() => this.toggleCollapse("details")}
                                    >
                                        Product details
                                    </CollapseButton>
                                    <Collapse isOpen={this.state.isDetailsOpen}>
                                        <div className="pb-2">
                                            <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
                                        </div>
                                    </Collapse>
                                </Col>
                            </Row>
                        }
                        {deliveryAndReturnsText &&
                            <Row>
                                <Col className="pb-4">
                                    <CollapseButton 
                                        className={this.state.isReturnsOpen ? "active" : ""}
                                        onClick={() => this.toggleCollapse("returns")}
                                    >
                                        Delivery and returns
                                    </CollapseButton>
                                    <Collapse isOpen={this.state.isReturnsOpen}>
                                        <div className="pb-2">
                                            <div dangerouslySetInnerHTML={{ __html: deliveryAndReturnsText }} />
                                        </div>
                                    </Collapse>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                <RelatedProducts product={product} />
            </ContainerMax>
        )
    }
}

ProductContent.contextType = ShopContext

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allWp {
                    edges {
                        node {
                            ...optionsFragment
                        }
                    }
                }
            }
        `}
        render={data => {
            return (
                <ProductContent data={data} {...props} />
            ) 
        }}
    />
)