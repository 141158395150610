import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import ReactSlick from "react-slick"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { media } from "utils/Media"
import arrowRight from "images/arrow-right.svg"
import arrowLeft from "images/arrow-left.svg"

const SlickWrap = styled.div`
    padding: 0;

    @media ${media.md} {
        padding: 0 1rem;
    }

    @media ${media.lg} {
        padding: 0 2rem;
    }
`

// const HoverHintStyled = styled.div`
//     position: absolute;
//     z-index: 10;
//     left: 1rem;
//     bottom: 1rem;
//     border: 1px solid ${props => props.theme.colors.primary};
//     color: ${props => props.theme.colors.primary};
//     font-weight: ${props => props.theme.font.weight.bold};
//     font-size: ${props => props.theme.font.size.sm};
//     line-height: 1.1;
//     padding: .75rem 2rem .75rem 1rem;
//     background-color: white;

//     &:before,
//     &:after {
//         content: "";
//         height: 10px;
//         width: 2px;
//         background-color: black;
//         position: absolute;
//         top: 50%;
//         right: 1rem;
//         transform: translateY(-50%) rotate(0);
//     }

//     &:after {
//         transform: translateY(-50%) rotate(-90deg);
//     }
// `

const Border = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
`

const ReactSlickStyled = styled(ReactSlick)`
    border: 1px solid ${props => props.theme.colors.primary};
    line-height: 0;

    .slick-next,
    .slick-prev {
        width: 40px;
        height: 40px;
        background-color: ${props => props.theme.colors.primary};
        z-index: 10;
        left: -15px;

        @media ${media.md} {
            width: 50px;
            height: 50px;
            left: -1rem;
        }

        @media ${media.lg} {
            width: 87px;
            height: 87px;
            left: -2rem;
        }

        &:before {
            content: "";
            background-image: url("${arrowLeft}");
            background-position: center;
            background-size: 30%;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
    }

    .slick-next {
        left: auto;
        right: -15px;

        @media ${media.md} {
            right: -1rem;
        }

        @media ${media.lg} {
            right: -2rem;
        }

        &:before {
            background-image: url("${arrowRight}");
        }
    }
`

// const HoverHint = (props) => {
//     return (
//         <HoverHintStyled>
//             {props.isTouchDetected ? props.hintTextTouch : props.hintTextMouse}
//         </HoverHintStyled>
//     )
// }

class ProductSlider extends Component {

    render() {
        const { product } = this.props

        const rsProps = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        return (
            <SlickWrap>
                <Border>
                    <ReactSlickStyled {...rsProps}>
                        {product.media?.map(({image}, i) => {
                            // const rimProps = {
                            //     smallImage: {
                            //         alt: image.altText,
                            //         isFluidWidth: true,
                            //         src: image.localFile.childImageSharp.fluid.src,
                            //         srcSet: image.localFile.childImageSharp.fluid.srcSet,
                            //         sizes: image.localFile.childImageSharp.fluid.sizes
                            //     },
                            //     largeImage: {
                            //         src: image.localFile.childImageSharp.fluid.src,
                            //         width: 1410,
                            //         height: 1410
                            //     },
                            //     lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                            //     isHintEnabled: true,
                            //     hintComponent: HoverHint,
                            //     shouldHideHintAfterFirstActivation: false,
                            //     enlargedImagePosition: 'over'
                            // }
                            return (
                                <div key={i} className="p-2">
                                    {/* <ReactImageMagnify {...rimProps} /> */}
                                    <GatsbyImage
                                        image={image.localFile.childImageSharp.gatsbyImageData}
                                        alt={image.altText} />
                                </div>
                            );
                        })}
                    </ReactSlickStyled>
                </Border>
            </SlickWrap>
        );
    }
}

export default ProductSlider