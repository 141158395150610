import React, { Component } from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { ShopContext } from "utils/ShopContext"
import Button from "components/shared/Button"

const ProductItem = styled(Col)`
    .gatsby-image-wrapper {
        img {
            transform: scale(1);
            transition: all .4s ease !important;
        }
    }

    &:hover {
        .gatsby-image-wrapper {
            img {
                transform: scale(1.1);
            }
        }
    }
`

const ProductItemImgWrap = styled.a`
    display: block;
    border: 1px solid ${props => props.theme.colors.primary}; 
    padding: .5rem;

    .gatsby-image-wrapper {
        margin: 0 auto;
    }
` 

const ProductTitle = styled.div`
    font-weight: ${props => props.theme.font.weight.bold};
`

const ProductPrice = styled.div`
   font-weight: ${props => props.theme.font.weight.bold};
   color: ${props => props.theme.colors.secondary};

   span {
        text-decoration: line-through;
        color: ${props => props.theme.colors.grey3};

        &:last-child {
            text-decoration: none;
            color: ${props => props.theme.colors.tertiary};
        }
   }
`

class Product extends Component {
    constructor(props) {
        super(props);
    
        let defaultOptionValues = {};
        this.props.product.options.forEach((selector) => {
            defaultOptionValues[selector.name] = selector.values[0].value;
        })

        this.state = { 
            selectedOptions: defaultOptionValues, 
            selectedVariant: this.props.product.variants[0],
            selectedVariantQuantity: 1
        }
    }

    render() {
        const { product } = this.props
        const { selectedVariant, selectedVariantQuantity } = this.state
        let price 
        const minPrice = Number(product.priceRangeV2?.minVariantPrice.amount).toFixed(2)
        const maxPrice = Number(product.priceRangeV2?.maxVariantPrice.amount).toFixed(2)
        
        if (minPrice === maxPrice) {
            const currentPrice = Number(product.variants[0].price).toFixed(2)
            const originalPrice = Number(product.variants[0].compareAtPrice).toFixed(2)
            price = currentPrice !== originalPrice && product.variants[0].compareAtPrice !== null ? `<span>&pound;${originalPrice}</span> &nbsp; <span>&pound;${currentPrice}</span>` : `&pound;${minPrice}`
        } else {
            price = `&pound;${minPrice} - &pound;${maxPrice}`
        }

        return (
            <ProductItem sm={6} lg={4} xl={3} className="pb-4">
                <ProductItemImgWrap href={`/product/${product.handle}/`} className="mb-3">
                    {product.media[0] &&
                        <GatsbyImage
                            image={product.media[0].image.localFile.childImageSharp.gatsbyImageData}
                            alt={product.media[0].image.altText} />
                    }
                </ProductItemImgWrap>
                <div className="pb-3">
                    <ProductTitle className="text-center">
                        {product.title}
                    </ProductTitle>
                    <ProductPrice className="text-center" dangerouslySetInnerHTML={{ __html: price }} />
                </div>
                <Row className="justify-content-center">
                    <Col xs="auto" className="pb-2">
                        <Button 
                            href={`/product/${product.handle}/`} 
                            small
                            color="transparentSecondary"
                        >
                            Find out more
                        </Button>
                    </Col>
                    <Col xs="auto" className="pb-2">
                        {product.availableForSale && 
                            <>
                                {product.variants.length > 1 ? (
                                    <Button 
                                        href={`/product/${product.handle}/`}
                                        small
                                        color="transparentSecondary"
                                    >
                                        Options
                                    </Button>
                                ) : (
                                    <Button 
                                        onClick={() => 
                                            this.context.addVariantToCart(
                                                selectedVariant.shopifyId, 
                                                selectedVariantQuantity
                                            )
                                        }
                                        as="button"
                                        small
                                    >
                                        Add to Cart
                                    </Button>
                                )}
                            </>
                        }
                    </Col>
                </Row>
            </ProductItem>
        );
    }
} 

Product.contextType = ShopContext

export default Product