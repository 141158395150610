import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import ProductContent from "components/ProductContent/ProductContent"

const ProductPage = (props) => {
    const product = props.data.shopifyProduct
    const seo = {
        title: "",
        description: ""
    }

    return (
        <Layout 
            path={`/product/${product.handle}`} 
            seo={seo} 
        >
            <ProductContent product={product} />
        </Layout>
    )
}

export const pageQuery = graphql`query ($id: String!) {
  shopifyProduct(id: {eq: $id}) {
    shopifyId
    title
    descriptionHtml
    handle
    options {
      shopifyId
      name
      values
    }
    variants {
      shopifyId
      availableForSale
      title
      price
      compareAtPrice
      selectedOptions {
        name
        value
      }
    }
    media {
      ... on ShopifyMediaImage {
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

export default ProductPage