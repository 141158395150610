import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "reactstrap"
import ProductListItem from "components/shared/ProductListItem"

const RelatedProducts = (props) => {
    const { product } = props
    let shopifyRelatedProducts = false
    const wpProductPage = props.data.allWpProduct && props.data.allWpProduct.edges.filter(item => item.node.slug === product.handle)

    if (wpProductPage) {
        const acfRelatedProducts = wpProductPage[0] && wpProductPage[0].node.acfProduct.products

        if (acfRelatedProducts) {
            // flatten array
            let relatedProducts = []
            acfRelatedProducts.forEach(prod => {
                relatedProducts.push(prod.slug)
            })
        
            shopifyRelatedProducts = props.data.allShopifyProduct.edges.filter(item => relatedProducts.includes(item.node.handle))
        }
    }

    if (shopifyRelatedProducts.length > 0) {
        return (
            <Row className="py-3">
                <Col xs={12} className="pb-3">
                    <strong style={{ color: "black" }}>You may also like...</strong>
                </Col>
                {shopifyRelatedProducts.map((product, i) => {
                    return(
                        <ProductListItem
                            key={i}
                            product={product.node}
                        />
                    )
                })}
            </Row>
        )
    } else return ""
}


export default (props) => (
    <StaticQuery
        query={graphql`{
  allShopifyProduct {
    edges {
      node {
        handle
        # availableForSale
        title
        priceRangeV2 {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
          availableForSale
          title
          price
          compareAtPrice
        }
        options {
          shopifyId
          name
          values
        }
        media {
          ... on ShopifyMediaImage {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
  allWpProduct {
    edges {
      node {
        slug
        acfProduct {
          products {
            ... on WpProduct {
              slug
            }
          }
        }
      }
    }
  }
}
`}
        render={data => (
            <RelatedProducts data={data} {...props} />
        )}
    />
)