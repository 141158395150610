import React, { Component } from "react"
import { Row, Col } from "reactstrap"
import styled, { css } from "styled-components"

const Options = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
`

const OptionButton = styled.button`
    position: relative;
    margin-right: .5rem;
    border: 0;
    background-color: transparent;
    padding: .5rem;
    font-weight: ${props => props.theme.font.weight.bold};

    ${props => props.active && css`
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            background: black;
        }
    `}
`

class ProductVariantSelector extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOption: 0
        }

        this.handleOptionChange = this.handleOptionChange.bind(this)
    }

    handleOptionChange(i) {
        this.setState({
            selectedOption: i
        })
    }

    render() {
        return (
            <Row>
                <Col className="pb-3">
                    <div className="pb-2 text-uppercase">
                        <strong>{this.props.option.name}</strong>
                    </div>
    
                    <Options>
                        {this.props.option.values.map((value, i) => {
                            return (
                                <li key={`${this.props.option.name}-${value}`}>
                                    <OptionButton 
                                        onClick={() => {
                                            this.handleOptionChange(i)
                                            this.props.handleOptionChange(this.props.option.name, value)
                                        }}
                                        active={i === this.state.selectedOption}
                                    >
                                        {value}
                                    </OptionButton>
                                </li>
                            )
                        })}
                    </Options>
                </Col>
            </Row>
        )
    }
}

export default ProductVariantSelector